import React from 'react'
import { StepComponentProps, SingleChoiceQuizStep } from '@types'
import { MapSelectOptions } from '../../SelectOption'
type Props = StepComponentProps<SingleChoiceQuizStep, string>

export default function Insurance(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props

  const handleInputChange = (value: string) => {
    handleNext(value)
  }

  const handleNext = (newValue: string) => {
    onChange(step.id, newValue)
  }

  const options = step.options.map(option => ({
    value: option.value,
    content: option.name,
    selected: value == option.value,
    onClick: handleInputChange,
  }))

  return (
    <>
      <div className="flex flex-wrap min-w-full max-w-2xl items-center justify-center text-sm gap-2.5 md:my-2">
        {MapSelectOptions({
          height: 'h-30',
          width: 'w-full',
          options,
        })}
      </div>
    </>
  )
}
