/* eslint-disable @next/next/no-img-element */
import { StepComponentProps, ContentQuizStep } from '@types'
import Actions from '@components/quiz/actions'
import { useAppSelector } from 'redux/hooks'
import { selectHeight } from 'redux/quiz'
import { selectCurrentWeight } from 'redux/user'

type ContentProps = StepComponentProps<ContentQuizStep, undefined>

const ContentVerify: React.FC<ContentProps> = (props: ContentProps) => {
  const s = useAppSelector(state => state)
  const height = useAppSelector(state => selectHeight(state, 'imperial'))
  const currentWeight = useAppSelector(state =>
    selectCurrentWeight(state, 'imperial'),
  )
  const {
    weight_loss,
    birthday,
    state,
    zip_code,
    health_conditions,
    conditions,
    pregnant,
    insurance_provider,
  } = s.quiz.formState

  return (
    <div className="max-w-md mx-auto">
      <div className="rounded-2xl  border-2 bg-gray-50 border-secondary1/10 p-5">
        <div className="flex gap-4 mb-4">
          <div className="quiz-values w-1/2">
            <div className="label">Birthday:</div>
            <div className="value">
              {birthday.month}/{birthday.day}/{birthday.year}
            </div>
          </div>
          <div className="quiz-values w-1/2">
            <div className="label">Height:</div>
            <div className="value">
              {height.feet}&apos; {Math.round(height.inches)}&quot;
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="quiz-values w-1/2">
            <div className="label">Location:</div>
            <div className="value">
              {state} - {zip_code}
            </div>
          </div>
          <div className="quiz-values w-1/2">
            <div className="label">Goal weight:</div>
            <div className="value">
              {(currentWeight || 0) - (weight_loss || 0)} lbs
            </div>
          </div>
        </div>
        {insurance_provider && (
          <div className="quiz-values mt-4">
            <div className="label">Insurance provider:</div>
            <div className="value capitalize">
              {insurance_provider.replaceAll('_', ' ')}
            </div>
          </div>
        )}
        {health_conditions && (
          <div className="quiz-values mt-4">
            <div className="label">Health conditions:</div>
            <div className="value capitalize">
              {health_conditions
                .map((c: string) => c.replaceAll('_', ' '))
                .join(', ')}
            </div>
          </div>
        )}
        {!conditions.includes('none') && (
          <div className="quiz-values mt-4">
            <div className="label">Other conditions:</div>
            <div className="value capitalize">
              {conditions.map((c: string) => c.replaceAll('_', ' ')).join(', ')}
            </div>
          </div>
        )}
        {pregnant !== 'no' && (
          <div className="quiz-values mt-4">
            <div className="label">Pregnant:</div>
            <div className="value capitalize">Yes</div>
          </div>
        )}
      </div>
      <Actions
        nextEnabled={true}
        buttonText="Start the Quiz"
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </div>
  )
}

export default ContentVerify
