/* eslint-disable @next/next/no-img-element */
import { StepComponentProps, ContentQuizStep } from '@types'
import Actions from '@components/quiz/actions'

type ContentProps = StepComponentProps<ContentQuizStep, undefined>

const ContentIntro: React.FC<ContentProps> = (props: ContentProps) => {
  const steps = [
    {
      label: 'Medical history',
      time: '2 mins',
    },
    {
      label: 'Lifestyle & Diet',
      time: '1 min',
    },
    {
      label: 'View Results',
      time: '-',
    },
  ]
  return (
    <div className="max-w-xl mx-auto">
      <h2 className="font-serif text-3xl text-center mb-3 leading-10">
        {props.step.label}
      </h2>
      <div className="quiz-paragraph">
        <p className="text-lg">
          Measured combines modern medicine, diet, and personalized coaching to
          help you lose weight and keep it off.
        </p>
      </div>
      <div className="steps flex flex-col gap-4 mt-10 md:max-w-[300px] md:mx-auto">
        {/* Iterate throug heach step */}
        {steps.map((step, index) => (
          <div className="flex gap-3 items-center justify-between" key={index}>
            <div className="flex gap-3 items-center">
              <div className="rounded-full bg-yellow-800/20 h-8 w-8 flex items-center justify-center text-lg">
                {index + 1}
              </div>
              <p className="font-semibold">{step.label}</p>
            </div>
            <div className="text-secondary1/40">{step.time}</div>
          </div>
        ))}
      </div>
      <Actions
        nextEnabled={true}
        buttonText="Start the Quiz"
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </div>
  )
}

export default ContentIntro
