import { Experiment } from '@types'
import survey from '../survey'
import { questions } from '@data/questions/glp1'

const r1: Experiment = {
  ...survey,
  questions,
  id: 'glp1',
  funnel: 'FOX-glp1',
  default: false,
}

export default r1
